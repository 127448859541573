import React from 'react';
import ImagesLogo from 'images/logo.svg';
import ImagesArrowTop from 'images/arrow-top.svg';
import { Container, Row, Col } from 'styled-bootstrap-grid';
import { animateScroll } from 'react-scroll';
import {
  Wrapper,
  Logo,
  ContactDetails,
  Address,
  Bottom,
  Copyright,
  ContactValue,
  BackToTop,
  Tags,
  TagsRow
} from './styled';

const Footer = () => (
  <>
    <Wrapper>
      <Container>
        <Row>
          <Col md={6} lg={4}>
            <Logo to="/">
              <img src={ImagesLogo} alt="Innovatica" />
            </Logo>
          </Col>
          <Col md={6} lg={4}>
            <ContactDetails>
              <tbody>
                <tr>
                  <td>Email:</td>
                  <ContactValue>hello@innovatica.com</ContactValue>
                </tr>
                <tr>
                  <td>Sales:</td>
                  <ContactValue>+1 (917) 979 0664</ContactValue>
                </tr>
              </tbody>
            </ContactDetails>
          </Col>
          <Col md={12} lg={4}>
            <Address>240 Kent Ave, Brooklyn, NY 11249</Address>
          </Col>
        </Row>

        <Bottom>
          <Copyright>All rights reserved - Innovatica - 2017 - 2018</Copyright>

          <BackToTop onClick={() => animateScroll.scrollToTop()}>
            <img src={ImagesArrowTop} alt="Back to top" />
          </BackToTop>
        </Bottom>
      </Container>
    </Wrapper>

    <Tags>
      <Container>
        <TagsRow>
          <span>DevOps</span>
          <span>Technical due diligence</span>
          <span>Quality assurance</span>
          <span>IT advisory</span>
          <span>Niche technology stack</span>
          <span>IoT solutions</span>
          <span>Cloud Computing</span>
          <span>Intelligent infrastructure</span>
        </TagsRow>
        <TagsRow>
          <span>Digital innovations</span>
          <span>Startups</span>
          <span>MVP development</span>
          <span>Team scaling</span>
          <span>Look&amp;Feel</span>
          <span>Agile software development</span>
          <span>Build software </span>
          <span>Compelte delivery - CTO</span>
        </TagsRow>
        <TagsRow>
          <span>Time to market oriented</span>
          <span>Business analysis</span>
          <span>Multilevel cloud migration</span>
          <span>Serverless architecture</span>
          <span>Blockchain</span>
          <span>UX Services</span>
          <span>Google cloud computing</span>
        </TagsRow>
      </Container>
    </Tags>
  </>
);

export default Footer;
