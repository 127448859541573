import React from 'react';
import Container from 'styled-bootstrap-grid/dist/Container';
import { scroller } from 'react-scroll';
import FileLogo from 'images/logo.svg';
import { Wrapper, Logo, GetEstimateButton } from './styled';

const Navbar = () => (
  <Container>
    <Wrapper>
      <Logo to="/">
        <img src={FileLogo} alt="Innovatica" />
      </Logo>

      <GetEstimateButton
        onClick={() =>
          scroller.scrollTo('contact', {
            duration: 800,
            delay: 0,
            smooth: 'easeInOutQuart'
          })
        }
      >
        Get estimate
      </GetEstimateButton>
    </Wrapper>
  </Container>
);

export default Navbar;
