import styled from 'styled-components';
import { media, colors } from 'themes';

export const Wrapper = styled.section`
  padding: 100px 0 150px;
  overflow: hidden;

  ${media.md`
    padding-top: 20px;
    padding-bottom: 70px;
  `}
`;

export const Referal = styled.div`
  padding-right: 50px;

  ${media.md`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 64px;
  `}

  ${media.sm`
    display: block;
    padding: 0 16px;
  `}
`;

export const Title = styled.h2`
  color: #2b2b2b;
  font-size: 32px;
  font-weight: bold;
  line-height: 39px;
  text-align: center;
  margin-bottom: 88px;

  ${media.sm`
    font-size: 24px;
    line-height: 32px;
    padding: 0 50px;
  `}
`;

export const Company = styled.div`
  color: #2b2b2b;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
`;

export const Person = styled.div`
  color: #798291;
  margin-top: 4px;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
`;

export const Heading = styled.div`
  color: #2b2b2b;
  font-size: 24px;
  font-weight: bold;
  line-height: 32px;
  margin-top: 32px;

  ${media.md`
    margin-top: 0;
  `}

  ${media.sm`
    margin-top: 32px;
  `}

  span {
    position: relative;
    display: inline-block;

    &:after {
      content: '';
      position: absolute;
      bottom: 1px;
      left: 0;
      width: 100%;
      height: 4px;
      background: ${colors.caribbeanGreen};
    }
  }
`;

export const Description = styled.div`
  color: #798291;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin-top: 24px;
`;

export const Brand = styled.div``;

export const Content = styled.div`
  ${media.md`
    width: 400px;
  `}

  ${media.sm`
    width: 100%;
  `}
`;
