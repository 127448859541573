import styled from 'styled-components';
import Img from 'gatsby-image';
import { media } from 'themes';

export const Wrapper = styled.section`
  background-color: #fbfbfb;
  padding: 100px 0 150px;
  overflow: hidden;
`;

export const Title = styled.h2`
  color: #2b2b2b;
  font-size: 32px;
  font-weight: bold;
  line-height: 39px;
  text-align: center;
  margin-bottom: 88px;
`;

export const Card = styled.div`
  position: relative;
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 0 10px 20px -10px rgba(110, 110, 110, 0.25);
  padding: 48px 505px 112px 95px;

  ${media.md`
    padding-right: 360px;
    padding-left: 64px;

    ${({ invert }) => invert && `
      padding-left: 360px;
      padding-right: 64px;
    `}
  `}

  ${media.sm`
    padding: 30px 20px 30px 20px;
    margin-left: 15px;
    margin-right: 15px;
  `}
  
  ${({ invert }) => invert && `
    padding: 48px 100px 112px 500px;
  `}

  & + & {
    margin-top: 184px;

    ${media.md`
      margin-top: 50px;
    `};
  }
`;

export const CardTitle = styled.div`
  color: #2b2b2b;
  font-size: 24px;
  font-weight: bold;
  line-height: 29px;
  margin-bottom: 40px;
`;

export const Description = styled.div`
  color: #798291;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
`;

export const Image = styled(Img)`
  position: absolute !important;

  ${media.md`
    width: 100px;
  `}

  ${media.sm`
    display: none !important;
  `}

  ${({ healthCare, eCommerce, iot }) => {
    if (healthCare) {
      return `
        top: -32px;
        right: -32px;
      `;
    }

    if (eCommerce) {
      return `
        top: -11px;
        left: -226px;
      `;
    }

    if (iot) {
      return `
        top: -40px;
        right: -220px;
      `;
    }
  }}

  ${media.md`
    ${({ healthCare, eCommerce, iot }) => {
      if (healthCare) {
        return `
          top: -32px;
          right: -132px;
        `;
      }

      if (eCommerce) {
        return `
          top: -11px;
          left: -366px;
        `;
      }

      if (iot) {
        return `
          top: -40px;
          right: -360px;
        `;
      }
    }}
  `}
`;
